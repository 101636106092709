import React from 'react';

import Layout from 'components/layout';
import { ThankYou } from 'components/Store/ThankYou';
import { STORE_ORDER_SUCCESS_SEO } from 'utils/seo';

const ThankYouPage: React.FC = () => (
  <Layout {...STORE_ORDER_SUCCESS_SEO}>
    <ThankYou />
  </Layout>
);

export default ThankYouPage;
