import React from 'react';
import { Link } from 'gatsby';

import { HOMEPAGE_ROUTE } from 'utils/routes';

import styles from './ThankYou.module.css';

export const ThankYou: React.FC = () => {
  return (
    <div className={styles.page}>
      <h1 className={styles.title}>Thank You!</h1>
      <p className={styles.subtitle}>Your order was successful.</p>
      <Link to={HOMEPAGE_ROUTE} className={styles.btn}>
        Go back home
      </Link>
    </div>
  );
};
